import React, { useState } from "react";
// Chakra imports
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
// Assets
import illustration from "assets/img/logo/fav.webp";
import illustrationWhite from "assets/img/logo/fav-w.webp";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import DefaultAuth from "layouts/auth/Default";
import { HSeparator } from "components/separator/Separator";
import { zodResolver } from "@hookform/resolvers/zod";
import { ILoginInputs, loginSchema } from "schema/auth";
import { SubmitHandler, useForm } from "react-hook-form";
import { useAuth } from "contexts/auth";
import { callApi} from "apiServices/callApi";
import { apiRoutes } from "constants/apiRoutes";
import { IApiMetaData } from "interfaces/apiMetaData";

function Login() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [loading, setLoading]             = useState(false);
  const [isError, setIsError]             = useState(false);
  const [rememberMe, setRememberMe]       = useState(true);
  const {userLogin}                       = useAuth()




  const {handleSubmit,register,formState:{errors,isSubmitting}} = useForm<ILoginInputs>({
    resolver: zodResolver(loginSchema),
    mode :"onChange"
  })
  
  const onSubmit: SubmitHandler<ILoginInputs> = async (data: ILoginInputs) => {
    setIsError(false)
    setLoading(true)

    const loginApi = apiRoutes.auth.login
    const response: IApiMetaData = await callApi("POST", loginApi, data)    
    
    if (response?.Status === 200) {
      const token = response?.Data?.Content?.token
      const data = response?.Data?.Content
        userLogin(token,data)
    } else {
      setIsError(true)
      
    }
    setLoading(false)
  }

  const imageMode = localStorage.getItem("chakra-ui-color-mode") === "dark" ? illustrationWhite : illustration

  return (
    <DefaultAuth illustrationBackground={imageMode} image={imageMode}>

      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w="100%"
        mx={{ base: "auto", lg: "0px" }}
        me="auto"
        h="100%"
        alignItems="start"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection="column"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            <span style={{ color: '#237fd5' }}>CIUK </span>Library
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color={textColorSecondary}
            fontWeight="400"
            fontSize="md"
            
          >
            Enter your email and password to login
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
          mx={{ base: "auto", lg: "unset" }}
          me="auto"
          mb={{ base: "20px", md: "auto" }}
        >

          <Flex align="center" mb="25px">
            <HSeparator />
            {/* <Text color="gray.400" mx="14px">
              or
            </Text> */}
            <HSeparator />
          </Flex>
          <FormControl isInvalid={!!errors?.email}>
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              {...register("email")}
              isRequired={true}
              variant="auth"
              fontSize="sm"
              ms={{ base: "0px", md: "0px" }}
              type="email"
              placeholder="mail@domain.com"
              fontWeight="500"
              size="lg"
            />
            {errors?.email && <FormErrorMessage ml="10px">{ errors?.email.message}</FormErrorMessage>}
          </FormControl>
          <FormControl isInvalid={!!errors?.password}>
            <FormControl isInvalid={!!errors?.password}>
              <FormLabel
                ms="4px"
                mt="24px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                Password<Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md">
                <Input
                  {...register("password")}
                  isRequired={true}
                  fontSize="sm"
                  placeholder="Password"
                  size="lg"
                  type={show ? "text" : "password"}
                  variant="auth"
                />

                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: "pointer" }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                    />
                </InputRightElement>
              </InputGroup>
            </FormControl>
                  {errors?.password && <FormErrorMessage >{ errors?.password.message}</FormErrorMessage>}
            <Flex justifyContent="space-between" align="center" mb="24px">
              <FormControl mt="24px" display="flex" alignItems="center">
                <Checkbox
                  id="remember-login"
                  colorScheme="brandScheme"
                  me="10px"
                  isChecked={rememberMe}
                  onChange={(e) => {setRememberMe(e.target.checked)}}
                />
                <FormLabel
                  htmlFor="remember-login"
                  mb="0"
                  fontWeight="normal"
                  color={textColor}
                  fontSize="sm"
                >
                  Keep me logged in
                </FormLabel>
              </FormControl>
            </Flex>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="100%"
              h="50"
              mb="24px"
              style={{backgroundColor: '#237fd5'}}
              onClick={handleSubmit(onSubmit)}
            >
              {loading ? <Spinner />  : "Login"}
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="start"
            maxW="100%"
            mt="0px"

          >
             {
              isError && <Alert status='error' borderRadius={'15px'}>
                              <AlertIcon />
                              <AlertDescription>Invalid Email or Password</AlertDescription>
                          </Alert>
            }

          </Flex>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default Login;
