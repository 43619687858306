// Chakra imports
import { Box, Button, Flex, Icon, Image, Text, useColorModeValue } from '@chakra-ui/react';
import { callApi } from 'apiServices/callApi';
// Custom components
import Card from 'components/card/Card';
import { environments } from 'constants/environments';
import { useAuth } from 'contexts/auth';
import { IApiMetaData } from 'interfaces/apiMetaData';
// Assets
import { useEffect, useState } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

export default function CardItem(props: {
	image: string;
	name: string;
	author: string;
	id?:string;
	currentbid: string | number;
}) {
	const { image, name, author, currentbid,id } = props;
	const [like, setLike] = useState(false);
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
	const { sharedData,setSharedData } = useAuth()
	const itemFavoritesAPI = environments.domain + sharedData?.apisList.User.General
  
	useEffect(() => { 
		if (sharedData?.user?.favorites?.includes(id)) {
            setLike(true);
        }
	},[])

	const handlingFavorite = async (id: any) => {
		setLike(!like); 
		if (sharedData?.user?.favorites?.includes(id)) {
			const response: IApiMetaData = await callApi("PUT", itemFavoritesAPI, { favorites: id }, {}, { target: "delete" }, false);
			if (response.Status === 200) {
				const deleteFavoriteFromSharedData = sharedData?.user?.favorites?.filter((item: any) => item !== id);
				setSharedData((prev: any) => ({
					...prev,
					user: {
						...prev.user,
						favorites: deleteFavoriteFromSharedData
					}
				}));
			} 
			
		} else {
			const response: IApiMetaData = await callApi("PUT", itemFavoritesAPI, { favorites: id }, {}, { target: "add" }, false);
			if (response.Status === 200) { 
				const addFavoriteToSharedData = [...sharedData?.user?.favorites, id];
				setSharedData((prev: any) => ({
					...prev,
					user: {
						...prev.user,
						favorites: addFavoriteToSharedData
					}
				}));
			}
			
		}
	};

	
	return (
		<Card p='20px' className='hover-card'>
			<Flex direction={{ base: 'row' }} justify='space-between' h="180px">
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative' style={{width: '30%', height: '100%'}}>
					<Image
						src={image}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '100%', '3xl': '100%' }}
						borderRadius='20px'
						alt={name}
						loading='lazy'
					/>
					<Button
						position='absolute'
						bg='white'
						_hover={{ bg: 'whiteAlpha.900' }}
						_active={{ bg: 'white' }}
						_focus={{ bg: 'white' }}
						p='0px !important'
						top='14px'
						right='14px'
						borderRadius='50%'
						minW='36px'
						h='36px'
						onClick={() => {
							handlingFavorite(id)
						}}>
						<Icon
							transition='0.2s linear'
							w='20px'
							h='20px'
							as={like ? IoHeart : IoHeartOutline}
							color='brand.500'
						/>
					</Button>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%' w="65%">
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{name}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{author}
							</Text>
						</Flex>
					</Flex>
					<Flex
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						justify='space-between'
						
						mt='25px'>
						<Text fontWeight='700' fontSize='sm' color={textColorBid}>
							{currentbid}
						</Text>
						<Link to={`/home/book/${id}`}>
							<Button
								variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'>
								View
							</Button>
						</Link>

					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
