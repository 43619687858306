
import { Box, Skeleton, SkeletonCircle, SkeletonText, useColorModeValue } from "@chakra-ui/react";


function SkeletonSubCategory() {
  let cardModeLoding      = useColorModeValue('#fff', '#111c44');



  return (
    <Box padding='6' paddingTop='20' boxShadow='lg' bg={cardModeLoding} rounded={'md'} h={"80vh"} w={"100%"} style={{display: "flex", justifyContent: "space-between", flexWrap: "wrap"}} >
							<Box className='col-12 md:col-6 lg:col-4'>
									<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
										<Box mb='20px' position='relative' width='30%' height='180px'>
											<Skeleton height='100%' width='100%' borderRadius='20px' />
											<SkeletonCircle size='36px' position='absolute' top='14px' right='14px' />
										</Box>
										<Box width='65%' height='170px' display='flex' flexDirection='column' justifyContent='space-between'>
											<Box mb='auto'>
												<SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='6' width='60%' />
												<SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width='40%' />
											</Box>
											<Box mt='25px' display='flex' justifyContent='space-between' alignItems='center'>
												<SkeletonText noOfLines={1} skeletonHeight='4' width='30%' />
												<Skeleton height='32px' width='70px' borderRadius='70px' />
											</Box>
										</Box>
										
									</div>
							</Box>
							<Box className='col-12 md:col-6 lg:col-4'>
									<div style={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
										<Box mb='20px' position='relative' width='30%' height='180px'>
											<Skeleton height='100%' width='100%' borderRadius='20px' />
											<SkeletonCircle size='36px' position='absolute' top='14px' right='14px' />
										</Box>
										<Box width='65%' height='170px' display='flex' flexDirection='column' justifyContent='space-between'>
											<Box mb='auto'>
												<SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='6' width='60%' />
												<SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='4' width='40%' />
											</Box>
											<Box mt='25px' display='flex' justifyContent='space-between' alignItems='center'>
												<SkeletonText noOfLines={1} skeletonHeight='4' width='30%' />
												<Skeleton height='32px' width='70px' borderRadius='70px' />
											</Box>
										</Box>
										
									</div>
							</Box>
							
					</Box>
  )
}

export default SkeletonSubCategory



