import { AxiosRequestConfig } from "axios";
import apiClient from "intercreptors/Interceptors";

export function sendFormURLEncoded(body: any) {
  let formBody: any = [];
  for (var property in body) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(body[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return formBody;
}

export const axiosConfig = (
  Method: string,
  Url: string,
  Headers: any,
  Data: any,
  isFormData: boolean = false
) => {
  if (isFormData) {
    const formData = new FormData();
    // for (const key in Data) {
    //   if (Object.prototype.hasOwnProperty.call(Data, key)) {
    //     formData.append(key, Data[key]);
    //   }
    // }
    Object.keys(Data).forEach((key) => {
      Array.isArray(Data[key])
        ? Data[key].forEach((item: any) => {
            formData.append(key, item);
          })
        : formData.append(key, Data[key]);
    });
    Data = formData;
  } else {
    Headers["Content-Type"] = "application/json";
  }
  return {
    method: Method,
    url: Url,
    headers: Headers,
    data: Data,
  };
};

export const makeAxiosRequest = async (dataConfig: AxiosRequestConfig<any>) => {
  return await apiClient(dataConfig)
    .then(async (result) => {
      return result.data;
    })
    .catch(async (error: any) => {
      return error;
    });
};

