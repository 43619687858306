import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex, useColorModeValue, SimpleGrid, Heading } from '@chakra-ui/react';
import Card from "components/card/Card";
import { environments } from 'constants/environments';
import { useAuth } from 'contexts/auth';
import { callApi } from 'apiServices/callApi';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { IHeadersDataPagination } from 'interfaces/pagination';
import { FaHashtag } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface IProps {
  id: string;
}

const Category: React.FC<IProps> = ({ id }) => {
  
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const [data, setData] = useState<any[]>([]);
  const [pagination,  ] = useState<IHeadersDataPagination>({
    paginationcurrent: 1,
    paginationlimit: 10000,
    paginationtotal: 0,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const { sharedData } = useAuth();
  const categoryAPI = environments.domain + sharedData?.apisList.Category.GetById;

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: IApiMetaData = await callApi("GET", categoryAPI, {}, pagination, {
        id,
        select: "subCategories",
        populatefields: "subCategories",
        populateselected: "name",
      });

      if (response.Status === 200) {
        setData(response.Data.Content.subCategories);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [categoryAPI, id, pagination]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  return (
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
      <Flex flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
        <Flex direction='column'>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} gap='20px' mb={{ base: '20px', xl: '0px' }}>
            {data.map((item: any) => (
              <Link to={`/home/subCategories/${item._id}`} key={item._id}>
                <Card className='hover-card'>
                  <Flex minWidth='max-content' alignItems='center' gap='2'>
                    <Box>
                      <Heading size='md'><FaHashtag size={20} color='#237fd5' /></Heading>
                    </Box>
                    <Box>
                      <Heading size='md' color={textColor}>{item.name.charAt(0).toUpperCase() + item.name.slice(1)}</Heading>
                    </Box>
                  </Flex>
                </Card>
              </Link>
            ))}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Category;
