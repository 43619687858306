import React, { useEffect, useState, useCallback } from 'react';
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
import NFT from 'components/card/CardItem';
import { environments } from 'constants/environments';
import { useAuth } from 'contexts/auth';
import { callApi } from 'apiServices/callApi';
import { IApiMetaData } from 'interfaces/apiMetaData';
import { useParams } from 'react-router-dom';
import SkeletonSubCategory from 'shared/SkeletonSubCategory';

const SubCategory: React.FC = () => {
  const [data, setData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ , setIsError] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const { sharedData } = useAuth();
  const subCategoriesInProgramAPI = environments.domain + sharedData?.apisList.SubCategory.GetById;

  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response: IApiMetaData = await callApi("GET", subCategoriesInProgramAPI, {}, {}, {
        id,
        select: "items",
        populatefields: "items",
        populateselected: "title supTitle cover keywords",
        active: true,
      });

      if (response.Status === 200) {
        setData(response.Data.Content.items);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [subCategoriesInProgramAPI, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (isLoading) {
    return <SkeletonSubCategory />;
  }

  return (
    <Box pt={{ base: '180px', md: '80px', xl: '80px' }}>
      <Flex flexDirection='column' gridArea={{ xl: '1 / 1 / 2 / 3', '2xl': '1 / 1 / 2 / 2' }}>
        <Flex direction='column'>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 3 }} gap='20px' mb={{ base: '20px', xl: '0px' }}>
            {data.map((item) => (
              <NFT
                id={item?._id}
                key={item?._id}
                name={item?.title}
                author={item?.supTitle}
                image={item?.cover}
                currentbid={item?.keywords?.map((key: any) => `#${key[0]?.toUpperCase() + key.slice(1)} `)}
              />
            ))}
          </SimpleGrid>
        </Flex>
      </Flex>
    </Box>
  );
};

export default SubCategory;
