
export const apiRoutes = {

    auth :{
        login: '/api/auth/userLogin',
        checkUser : '/api/auth/checkUser',
    },
    sheardData: {
        get: '/api/auth/sharedData',
    }

}
