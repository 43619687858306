// chakra imports
import { Box, Flex, Icon, Stack } from "@chakra-ui/react";
import { callApi } from "apiServices/callApi";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { useState, useEffect } from "react";
import { FaFolderOpen } from "react-icons/fa";
import Category from "views/home/category";

// FUNCTIONS

function SidebarContent(props: { routes: RoutesType[] }) {
  const { routes } = props;
  const { sharedData } = useAuth()
  const categoriesInProgramAPI = environments.domain + sharedData?.apisList?.Category?.GetByIds
  const [data, setData] = useState<any[]>([]);
  const [dataRoutes, setDataRoutes] = useState<any[]>([]);

    
  useEffect(() => {
    const getData = async () => {
      const pagination = { paginationcurrent: 1, paginationlimit: 2000, paginationtotal: 0, }

      const response: IApiMetaData = await callApi("POST", categoriesInProgramAPI, {ids: sharedData?.user?.program}, pagination,{select:"name"});
      const arr = response?.Data?.Content?.map((item: any) => perparRouts(item?.name,item?._id))
      setDataRoutes(() => [...routes,...arr])      
      if (response.Status === 200) {
        setData(() => response.Data.Content);
      }
    };
    getData();
  }, [])


  
  const perparRouts = (name: string,id: string) => {


    let rou =  {
     name: String(name)[0].toUpperCase() + String(name).slice(1),
     layout: "/home",
     path: name,
     icon: (
       <Icon
         as={FaFolderOpen}
         width="20px"
         height="20px"
         color="inherit"
       />
     ),
     element: <Category id={id}/>,
     visibleMenu: true,
     secondary: true,
   }

   return rou 
  }
  
  // SIDEBAR
  return (
    <Flex direction="column" height="100%" pt="25px" borderRadius="30px">
      <Brand />
      <Stack direction="column" mt="8px" mb="auto">
        <Box ps="20px" pe={{ lg: "16px", "2xl": "16px" }}>
          <Links routes={dataRoutes} />
        </Box>
      </Stack>

      <Box
        ps="20px"
        pe={{ lg: "16px", "2xl": "20px" }}
        mt="60px"
        mb="40px"
        borderRadius="30px"
      >
        {/* <SidebarCard /> */}
      </Box>
    </Flex>
  );
}

export default SidebarContent;
