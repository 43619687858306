import { useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { Box, useColorModeValue } from "@chakra-ui/react";
import { SidebarContext } from "../../contexts/SidebarContext";
import routes from "../../routes";


export default function AuthLayout() {
  const [toggleSidebar, setToggleSidebar] = useState(false);

  const getRoutes = (routes: RoutesType[]): JSX.Element[] => {

    return routes
      .filter((route) => route.layout === "/auth")
      .map((route, key) => (
        <Route path={route.path} element={route.element} key={key} />
      ));
  };

  const authBg = useColorModeValue("white", "navy.900");
  document.documentElement.dir = "ltr";

  return (
    <Box>
      <SidebarContext.Provider value={{ toggleSidebar, setToggleSidebar }}>
        <Box
          bg={authBg}
          float="right"
          minHeight="100vh"
          height="100%"
          position="relative"
          w="100%"
          transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
          transitionDuration=".2s, .2s, .35s"
          transitionProperty="top, bottom, width"
          transitionTimingFunction="linear, linear, ease"
        >
          <Box mx="auto" minH="100vh">
            <Routes>
              {getRoutes(routes)}
              <Route path="/" element={<Navigate to="login" replace />} />
              <Route path="*" element={<Navigate to="login" replace />} />
            </Routes>
          </Box>
        </Box>
      </SidebarContext.Provider>
    </Box>
  );
}
