import { Icon } from "@chakra-ui/react";
import {
  MdLock,
  MdOutlineFavoriteBorder,
} from "react-icons/md";

// Admin Imports

// Auth Imports
import Login from "views/auth/login";
import {  FaHashtag } from "react-icons/fa";
import { GiWhiteBook } from "react-icons/gi";

import Book from "views/home/books";
import SubCategory from "views/home/subCategory";
import Favorites from "views/home/favorites";



const routes: RoutesType[] = [

  {
    name: "Login",
    layout: "/auth",
    path: "login",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    element: <Login />,
    visibleMenu: false,
  },
  {
    name: "Categories",
    layout: "/home",
    path: "subCategories/:id",
    icon: (
      <Icon
        as={FaHashtag}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <SubCategory/>,
    visibleMenu: false,
    secondary: false,
  },
  {
    name: "Books",
    layout: "/home",
    path: "book/:id",
    icon: (
      <Icon
        as={GiWhiteBook}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <Book />,
    visibleMenu: false,
    secondary: false,
  },
  {
    name: "Favorites",
    layout: "/home",
    path: "favorites",
    icon: (
      <Icon
        as={MdOutlineFavoriteBorder}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    element: <Favorites/>,
    visibleMenu: true,
    secondary: true,
  }
];

export default routes;
