import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../assets/css/Chatbot.css';

const Chatbot: React.FC = () => {
  const [messages, setMessages] = useState<{ sender: string; text: string }[]>([]);
  const [input, setInput] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [chatOpen, setChatOpen] = useState<boolean>(false);
  const [botTyping, setBotTyping] = useState<string>(''); // Holds the text while bot is "typing"

  const API = process.env.REACT_APP_API_KEY;

  // Save messages to localStorage
  useEffect(() => {
    if (messages.length > 0) {
      localStorage.setItem('chatMessages', JSON.stringify(messages));
    }
  }, [messages]);

  // Retrieve messages from localStorage on component mount
  useEffect(() => {
    const savedMessages = localStorage.getItem('chatMessages');
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  const typeText = (text: string) => {
    return new Promise<void>((resolve) => {
      let index = 0;
      const interval = setInterval(() => {
        if (index < text.length) {
          setBotTyping((prev) => prev + text[index]);
          index++;
        } else {
          clearInterval(interval);
          resolve(); // Finish typing
        }
      }, 10); // Adjust typing speed here
    });
  };

  const sendMessage = async (message: string) => {
    const userMessage = { sender: 'user', text: message };
    setMessages((prevMessages) => [...prevMessages, userMessage]);
    setLoading(true);

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4o',
          messages: [{ role: 'user', content: message }],
        },
        {
          headers: {
            Authorization: `Bearer ${API}`,
            'Content-Type': 'application/json',
          }
        }
      );

      const botMessage = response.data.choices[0].message.content;
      setBotTyping(''); // Reset bot typing effect
      await typeText(botMessage); // Start typing effect

      setMessages((prevMessages) => [...prevMessages, { sender: 'bot', text: botMessage }]);
    } catch (error) {
      console.error('Error fetching chatbot response:', error);
    }

    setLoading(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (input.trim()) {
      sendMessage(input);
      setInput('');
    }
  };

  const toggleChat = () => {
    setChatOpen(!chatOpen);
  };

  const clearChat = () => {
    setMessages([]); // Clear messages from the state
    localStorage.removeItem('chatMessages'); // Clear messages from localStorage
  };

  return (
    <div className="chatbot-container">
      <div className={`chat-icon ${chatOpen ? 'open' : ''}`} onClick={toggleChat}>
        {/* Use any icon library like FontAwesome */}
        <span>💬</span>
      </div>

      {chatOpen && (
        <div className="chat-box">
          <div className="messages">
            {messages.map((msg, index) => (
              <div key={index} className={`message ${msg.sender}`}>
                {msg.text}
              </div>
            ))}
            {loading && botTyping && (
              <div className="message bot">{botTyping}</div>
            )}
            {loading && !botTyping && <div className="message bot">Typing...</div>}
          </div>
          <form onSubmit={handleSubmit}>
            <input
              className="input-chat"
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type a message..."
            />
            <button type="submit" disabled={loading} className="send-button">
              Send
            </button>
          </form>
          <div className='text-right mt-2'>
            <button type='button' onClick={clearChat} className="clear-chat-button">
              Clear Chat
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Chatbot;
