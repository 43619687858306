import React from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import './assets/css/theme.css';
import { BrowserRouter, Routes, Route, Navigate, } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import HomeLayout from './layouts/home';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { PrimeReactProvider } from 'primereact/api';
import { AuthProvider } from './contexts/auth'; 
import "assets/css/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <PrimeReactProvider>
        <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path="/auth/*" element={<AuthLayout />} />
                <Route path="/home/*" element={<HomeLayout />} />
                <Route path="*" element={<Navigate to="/home/favorites" replace />} />
              </Routes>
            </AuthProvider>
        </BrowserRouter>
      </PrimeReactProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
