import React, { useEffect, useState, useCallback } from 'react';
import { Box, Button, Center, Flex, Heading, Icon, SkeletonText, Spacer, useColorModeValue, Text } from "@chakra-ui/react";
import Card from "components/card/Card";
import { CgAttachment } from "react-icons/cg";
import { environments } from "constants/environments";
import { useAuth } from "contexts/auth";
import { IApiMetaData } from "interfaces/apiMetaData";
import { callApi } from "apiServices/callApi";
import ReactPlayer from 'react-player/lazy';
import { CalendarIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { FaPlay } from "react-icons/fa";
import { BsFilePdfFill } from 'react-icons/bs';
import { RiFilePpt2Fill } from "react-icons/ri";
import { IoHeart, IoHeartOutline } from "react-icons/io5";

const Book: React.FC = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [like, setLike] = useState(false);
  const [data, setData] = useState<any>();
  const [view, setView] = useState<{ name: string, url: string, type: string }>({ name: "", url: "", type: "" });
  const { id } = useParams<{ id: string }>();
  const cardMode = useColorModeValue('#fff', '#0b1437');
  const cardModeLoading = useColorModeValue('#fff', '#111c44');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const { sharedData, setSharedData } = useAuth();
  const booksAPI = environments.domain + sharedData?.apisList?.Item?.GetById;
  const itemFavoritesAPI = environments.domain + sharedData?.apisList.User.General;

  useEffect(() => {
    if (sharedData?.user?.favorites?.includes(id)) {
      setLike(true);
    }
  }, [id, sharedData?.user?.favorites]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response: IApiMetaData = await callApi("GET", booksAPI, {}, {}, { id });
      if (response.Status === 200) {
        setData(response.Data.Content);
        setView({
          name: response.Data.Content.title,
          url: response.Data.Content.isExternal ? response.Data.Content.externalLink : response.Data.Content.doc,
          type: response.Data.Content.type
        });
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [booksAPI, id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleFavorite = async (itemId: string) => {
    setLike(!like);
    try {
      const target = sharedData?.user?.favorites?.includes(itemId) ? "delete" : "add";
      const response: IApiMetaData = await callApi("PUT", itemFavoritesAPI, { favorites: itemId }, {}, { target }, false);

      if (response.Status === 200) {
        const updatedFavorites = target === "delete"
          ? sharedData?.user?.favorites?.filter((item: string) => item !== itemId)
          : [...sharedData?.user?.favorites, itemId];

        setSharedData((prev: any) => ({
          ...prev,
          user: {
            ...prev.user,
            favorites: updatedFavorites
          }
        }));
      }
    } catch (error) {
      console.error("Error updating favorites", error);
    }
  };

  if (isLoading) {
    return (
      <Box padding='6' boxShadow='lg' bg={cardModeLoading} rounded={'md'} h={"80vh"} w={"100%"} display="flex" justifyContent="center" alignItems="center">
        <Box w={"100%"}>
          <SkeletonText mt='4' noOfLines={1} spacing='4' skeletonHeight='300' w={"50%"} />
          <SkeletonText mt='4' noOfLines={10} spacing='4' skeletonHeight='2' />
        </Box>
      </Box>
    );
  }

  if (isError) {
    return <div>Error loading data.</div>;
  }

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <div>
          <Flex justify="space-between" align="flex-start" marginBottom="30px" flexWrap="wrap">
            <Box p='6' rounded='md' bg='white'  className={`col-12 ${data?.attachments?.length === 0 ? 'lg:col-12' : 'lg:col-8'}`}  style={{ background: cardMode }}>
              <div style={{ width: "100%" }}>
                <Center h='50px' color='white' bg='#5296d8' fontSize='xl' fontWeight='bold' mb='20px'>
                  {view.name.charAt(0).toUpperCase() + view.name.slice(1)}
                </Center>
                {view.type === 'video' ? (
                  <ReactPlayer url={view.url} controls={true} width='100%' height={data?.attachments?.length === 0 ? '600px' : '500px' } />
                ) : (
                  <iframe src={view.url} frameBorder="0" width='100%' height={data?.attachments?.length === 0 ? '600px' : '500px' } allowFullScreen></iframe>
                )}
              </div>
            </Box>
            {data?.attachments?.length > 0 && (
              <Box boxShadow='lg' p='6' rounded='md' bg='white' className="col-12 lg:col-3" style={{ background: cardMode }}>
                <Center h='50px' color='white' bg='#5296d8' fontSize='xl' fontWeight='bold' mb='20px'>Attachments</Center>
                {data?.attachments?.map((attachment: any) => (
                  <Card key={attachment._id} className="hover-attachment" style={{ backgroundColor: '#dbdde3', color: 'white' }} onClick={() => setView({ name: attachment.name, url: attachment.url, type: attachment.typeAttachment })}>
                    <Flex minWidth='max-content' alignItems='center' gap='2'>
                      <Box>
                        <Heading size='md'>
                          {attachment.typeAttachment === 'video' && <FaPlay size={25} color="#8f9bba" />}
                          {attachment.typeAttachment === 'pdf' && <BsFilePdfFill size={25} color="#8f9bba" />}
                          {attachment.typeAttachment === 'ppt' && <RiFilePpt2Fill size={25} color="#8f9bba" />}
                          {!['video', 'pdf', 'ppt'].includes(attachment.typeAttachment) && <CgAttachment size={25} color="#8f9bba" />}
                        </Heading>
                      </Box>
                      <Heading size='md' color='#090a52'>{attachment.name.charAt(0).toUpperCase() + attachment.name.slice(1)}</Heading>
                      <Spacer />
                    </Flex>
                  </Card>
                ))}
              </Box>
            )}
          </Flex>

          <hr />
          <div style={{ marginTop: "50px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <Text fontSize='3xl'>{data?.title}</Text>
                <Text fontSize='2xl' color={textColor}>{data?.supTitle}</Text>
              </div>
              <div>
                <Button
                  bg='#dbdde3'
                  _hover={{ bg: '#a5a7af' }}
                  _active={{ bg: 'white' }}
                  _focus={{ bg: 'white' }}
                  p='0px !important'
                  borderRadius='50%'
                  minW='46px'
                  h='46px'
                  onClick={() => handleFavorite(id!)}>
                  <Icon transition='0.2s linear' w='25px' h='25px' as={like ? IoHeart : IoHeartOutline} color='brand.500' />
                </Button>
              </div>
            </div>
            <div className="line-cardiff"></div>
            <Text fontSize='lg' color={textColor} marginBlock="50px" textAlign="justify">
              {data?.description}
            </Text>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "30px" }}>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {data?.keywords?.map((keyword: string) => (
                  <Text fontSize='md' color={textColor} fontWeight="bold" key={keyword} mr="10px">
                    #{keyword}
                  </Text>
                ))}
              </div>
              <div>
                <Text fontSize='md' color={textColor}>{new Date(data?.createdAt).toDateString()} <CalendarIcon color={textColor} verticalAlign="baseline" /></Text>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </Box>
  );
};

export default Book;
