// Chakra imports
import { Flex, Image } from "@chakra-ui/react";

// Custom components
import logoImage from "assets/img/logo/fav.webp";
import logoImageWhite from "assets/img/logo/fav-w.webp";
import { HSeparator } from "components/separator/Separator";
import { Link } from "react-router-dom";

export function SidebarBrand() {
  //   Chakra color mode

  return (
    <Link to={`/home`} className="mx-4">
    <Flex alignItems="center" flexDirection="column">
        {localStorage.getItem("chakra-ui-color-mode") === "dark" ? (<Image src={logoImageWhite}  h="120px" maxWidth={"100%"} />):(<Image src={logoImage}  h="120px" maxWidth={"100%"} />)}
      <HSeparator mb="20px" />
    </Flex>
  </Link>
  );
}

export default SidebarBrand;
