import { callApi } from "apiServices/callApi";
import { apiRoutes } from "constants/apiRoutes";
import { environments } from "constants/environments";
import { IApiMetaData } from "interfaces/apiMetaData";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate  } from "react-router-dom";
import Login from "views/auth/login";

export const AuthContext = createContext<any>(null);

export const AuthProvider = ({ children }: any) => {

    const [loggedIn, setLoggedIn]       = useState<boolean>(false);
    const [sharedData, setSharedData]   = useState<any>({});
    const navigate                      = useNavigate ();

    const userLogin = (token: any,data:any) => {        
        localStorage.setItem(environments.tokenKey, token);
        setLoggedIn(() => true);
        const {apisList ,...other} = data
        const shared = { apisList: data.apisList, user: other }
        
        setSharedData(() => shared);
        navigate('/home');
    }


    const userLogout = () => {
        setSharedData(null);
        setLoggedIn(()=>false);
        localStorage.removeItem(environments.tokenKey);
        navigate('/auth/login');
    }

    const checkUser = async () => {
        const token: any = localStorage.getItem(environments?.tokenKey);
        if (token) {
            const url = apiRoutes?.auth?.checkUser
            const response: IApiMetaData = await callApi("POST", url, {}, {}, {});
            if (response?.Status === 200) {
                const shared = {
                    apisList: response?.Data?.Content?.apisList,
                    user: response?.Data?.Content?.userExist
                }
                setSharedData(() => shared)
                setLoggedIn(() => true);
                navigate('/home');
            } else {
                userLogout()
                localStorage.removeItem(environments?.tokenKey);
            }
        } else {
            userLogout()
        }
    }

    useEffect(() => {
        checkUser()
    }, [])

    

    return (
        <AuthContext.Provider value={{ userLogin, userLogout,sharedData, setSharedData }}>
            {loggedIn ? children : <Login/>}
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    return useContext(AuthContext);
}
